(function ($) {
  Drupal.behaviors.mainNav = {
    attach: function (context, settings) {
      // write code to handle main nav drop-downs here
      // Legal Section in Top Header
      if ($('.site-header').length > 0) {
        $('.site-header').before($('.privacy_policy'));
      }
      if ($('.legal_close').length > 0) {
        $('.legal_close').bind('click', function (e) {
          $('.privacy_policy').slideUp();
        });
      }
    }
  };
})(jQuery);
